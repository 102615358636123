import React, { memo, useEffect, useState } from "react";
import {
  RadioGroup,
  Box,
  Button,
  Radio,
  IconButton,
  Tooltip,
  Fade,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Typography,
  Select,
  Stack,
  InputAdornment,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import TextField from "@mui/material/TextField";
import AddInvoiceItems from "./AddInvoiceItems";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../AlertDialog/AlertDialog";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import OrderToInvoice from "../../../pages/Invoice/OrderToInvoice";
import Calculation from "../../../utility/Calculations";
import WantToPrint from "./WantToPrint";
import configServ from "../../../services/config";
import ConfirmationDialog from "../../Dialog/ConfirmationDialog";
import { rSetShowFab } from "../../../redux/reducers/GlobalVariables";

//FUNCTION
function InvoiceCard({ editData, setEditData, setIsChanged, isChanged }) {
  //#region code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const { company_type } = useSelector((state) => state.admin);
  const [invoiceData, setInvoiceData] = useState({});
  const [open, setOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [oti, setOti] = useState(false);
  const [is_credit_limit, setIs_credit_limit] = useState(true);
  const [items, setItems] = useState([]);
  //const [firms, setFirms] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [isInvoiceNumber, setIsInvoiceNumber] = useState(true);
  const shipToAddressesInitial = [
    {
      id: "",
      address: "",
    },
  ];
  const [shipToAddresses, setShipToAddresses] = useState(
    shipToAddressesInitial
  );
  const invoiceInitial = {
    user_id: user_id,
    company_id: company_id,
    is_update: "",
    _token: "",
    contact_name: "",
    printID: "",
    buyeraddress: "",
    ShipToAddress: "",
    invhd1: "",
    invid: "",
    auto_gen: "on",
    invoice_number: "",
    date: Calculation.GetCurrentDate(),
    due_date: Calculation.getCurrentDatePlusDays(30),
    sales_order: "",
    salesperson: "",
    pay_mode: "",
    tcs: "",
    delivery_note: "",
    CgstVal: "",
    totalamtWithoutTcsTax: "",
    totaltaxwithoutTCS: "",
    customer_id: null,
    item: [
      {
        itemname: "",
        item_id: "",
        itemcode: "",
        packagingunit: "",
        hsn_code: "",
        tax_rate: "",
        qty: "",
        rate: "",
        amountWithoutTax: "",
        discount: "",
        tax_amount: "",
        amount: "",
        remarks: "",
      },
    ],
  };
  const [invoice, setInvoice] = useState(invoiceInitial);
  const invoiceErrorInitial = {
    contact_name: "",
    buyeraddress: "",
    ShipToAddress: "",
    date: "",
    due_date: "",
  };
  const [invoiceError, setInvoiceError] = useState(invoiceErrorInitial);
  const itemListInitial = [
    {
      id: 1,
      item_id: "",
      itemname: "",
      itemcode: "",
      packagingunit: "",
      hsn_code: "",
      tax_rate: "",
      qty: "",
      rate: "",
      amountWithoutTax: "",
      discount: 0,
      tax_amount: "",
      amount: "",
      remarks: "",
    },
  ];
  const [itemList, setItemList] = useState(itemListInitial);
  const itemListErrorInitial = [
    {
      itemname: "",
      qty: "",
    },
  ];
  const [itemListError, setItemListError] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState("Alert");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonType, setButtonType] = useState(null);

  const location = useLocation();
  const [redirectOrderToInvoice, setRedirectOrderToInvoice] = useState(
    location.state?.orderToInvoice || null
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (redirectOrderToInvoice) {
      scrollToTop();
      setIsEdit(false);
      setIsCreate(true);
      const inv = {
        contact_name: redirectOrderToInvoice.partyname,
        printID: "",
        buyeraddress: redirectOrderToInvoice.billtoaddress,
        ShipToAddress: redirectOrderToInvoice.shiptoaddress,
        auto_gen: "on",
        invoice_number: "",
        date: Calculation.GetCurrentDate(),
        due_date: Calculation.getCurrentDatePlusDays(30),
        order_id: redirectOrderToInvoice.id,
        sales_order: redirectOrderToInvoice.order_no,
        salesperson: redirectOrderToInvoice.salesperson,
        pay_mode: "",
        customer_id: redirectOrderToInvoice.customer_id,
      }
      setInvoice(inv);
      const itemlist = redirectOrderToInvoice.items.map((item, index) => (
        {
          id: index + 1,
          itemname: item.itemname,
          item_id: item.itemid,
          itemcode: item.item_code,
          packagingunit: "",
          hsn_code: item.hsn_code,
          tax_rate: item.Taxrate,
          qty: item.qty,
          rate: item.rate,
          amountWithoutTax: (item.amount - item.tax_amount),
          discount: item.discount,
          tax_amount: item.tax_amount,
          amount: item.amount,
          remarks: "",
        }
      ));
      setItemList(itemlist);
      fetchShipToAddress(redirectOrderToInvoice.customer_id);
    }
    else {
      setRedirectOrderToInvoice(null);
    }
  }, [redirectOrderToInvoice]);

  const handleOpenDialog = (heading, content, type) => {
    setTitle(heading);
    setDialogContent(content);
    setButtonType(type);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setTitle("Alert");
    setDialogContent("");
    setButtonType(null);
  };

  // const [itemSize, setItemSize] = useState([])

  // const fetchItemSize = async (id) => {
  //     try {
  //         const result = await configServ.getItemSize({ "item_id": id })
  //         const filtered = result.filter((item) => {
  //             return item.size !== 'Primary'
  //         })
  //         setItemSize(filtered)
  //     } catch (err) {
  //         console.log(err)
  //     }
  // }

  useEffect(() => {
    fetchCustomerList();
    fetchItemList();
  }, []);

  useEffect(() => {
    calculateTotalQuantity();
    calculateTotalTaxAmount();
    calculateTotalAmount();
  }, [itemList]);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setInvoice(editData);

      // const updatedItemList = editData.items.map((item, index) => ({
      //   ...item,   // Spread the existing item properties
      //   id: index + 1,   // Add or override the id field with an incremented value
      // }));
      
      const updatedItemList = CheckAndReturn.UpdateItemList(editData.items);
      setItemList(updatedItemList);
      
      setIsEdit(true);
      setIsCreate(true);
      fetchShipToAddress(editData.customer_id);
    }
  }, [editData]);

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({
        company_id: company_id,
      });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemList = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        search_by: null,
        search: null,
        per_page: null,
        page: null,
      };
      const res = await configServ.getItemList(dataToSend);
      if (res.status === 200) {
        if (res.data.length > 0) {
          const result = res.data;  //.filter((x) => x.status === 1);
          setItems(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchCustomers = async (cust_id) => {
  //   try {
  //     const result = await configServ.getShipToAddress({ cust_id: cust_id });
  //     setCustomers(result);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchShipToAddress = async (cust_id) => {
    try {
      if (cust_id !== undefined && cust_id !== null) {
        let result = [];
        try {
          result = await configServ.getShipToAddress({ cust_id: cust_id });
        } catch (ex) {
          console.log(ex);
        }

        if (result.length > 0) {
          setShipToAddresses(result);
          if (result[0].address !== undefined && result[0].address !== null) {
            setInvoice((prev) => ({
              ...prev,
              ShipToAddress: result[0].address,
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (invoice.contact_name) {
      const selectedCustomer = customers.find(
        (item) =>
          item.fname.toLowerCase() === invoice.contact_name.toLowerCase()
      );
      if (selectedCustomer !== undefined) {
        fetchShipToAddress(selectedCustomer.id);
      }
    }
  }, [invoice.contact_name]);

  // Function to add a new item to the list
  // Function to add a new item to the list right after the current item
  const handleAddItem = (currentItemId) => {
    try {
      setItemList((prevList) => {
        // Check if the list is empty
        if (prevList.length === 0) {
          // Return the list with the first item if it's empty
          return [{
            id: 1,
            name: "",
            qty: "",
          }];
        }

        // Find the index of the current item
        const currentIndex = prevList.findIndex((item) => item.id === currentItemId);

        // Handle case when currentItemId is not found
        if (currentIndex === -1) {
          console.error(`Item with id ${currentItemId} not found.`);
          return prevList; // Return the original list without changes
        }

        // Create new item
        const newItem = {
          id: Math.max(...prevList.map((item) => item.id)) + 1, // New ID
          name: "",
          qty: "",
        };

        // Insert new item right after the current item
        return [
          ...prevList.slice(0, currentIndex + 1), // Items before and including the current item
          newItem, // New item to be added
          ...prevList.slice(currentIndex + 1), // Items after the current item
        ];
      });
    }
    catch (error) {
      console.error("Error adding item:", error);
    }
  };

  // Function to delete an item from the list based on its ID
  const handleDeleteItem = (data) => {
    try {
      // Check if the data object is valid
      if (data !== null && data !== undefined) {
        const id = data.id;

        setItemList((prevList) => {
          // If there's more than one item, remove the selected one
          if (prevList.length > 1) {
            return prevList.filter((item) => item.id !== id);
          }

          // If only one item is left, reset to the initial state
          else if (prevList.length === 1) {
            return itemListInitial; // Assuming `itemListInitial` is your default list
          }

          return prevList; // Return the same list if no changes
        });
      }
      else {
        console.error("Invalid data object passed to handleDeleteItem.");
      }
    }
    catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const handleInputChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        if (value.length > 0) {
          if (name === "itemname") {
            // Find the selected item from the options
            const selectedItem = items.find(
              (item) =>
                `${item.item_name.toLowerCase()}(${item.item_code.toLowerCase()})` ===
                value.toLowerCase()
            );
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: selectedItem.id,
                itemname: selectedItem.item_name,
                itemcode: selectedItem.item_code,
                packagingunit: selectedItem.packagingunit,
                hsn_code: selectedItem.hsncode,
                discount: selectedItem.discount,
                qty: "",
                rate: selectedItem.rate_unit,
                tax_rate: selectedItem.tax_rate,
                issizeAdded: Boolean(selectedItem.issizeAdded),
                // size_id:selectedItem.size_id || null,
                tax_amount: "0",
                amount: "0",
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: "",
                itemname: "",
                itemcode: "",
                packagingunit: "",
                hsn_code: "",
                discount: "",
                rate: "",
                tax_rate: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuantityChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        const selectedItem = itemList.find((item) => item.id === id);
        if (value.toString().length > 0) {
          if (name === "qty") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                value,
                selectedItem.discount
              ));
              const amountWithoutTax = CheckAndReturn.roundToInteger(Calculation.GetAmount(
                selectedItem.rate,
                value
              ));
              const totalAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                value,
                selectedItem.discount
              ));
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                qty: value,
                amountWithoutTax: amountWithoutTax.toString(),
                tax_amount: taxAmount.toString(),
                amount: CheckAndReturn.roundToInteger(
                  totalAmount ?? 0
                ).toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                qty: "",
                amountWithoutTax: "0",
                tax_amount: "0",
                amount: "0",
              };
            }
          } else if (name === "rate") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                value,
                selectedItem.qty,
                selectedItem.discount
              ));
              const amountWithoutTax = CheckAndReturn.roundToInteger(Calculation.GetAmount(
                value,
                selectedItem.qty
              ));
              const totalAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                value,
                selectedItem.qty,
                selectedItem.discount
              ));
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                rate: value,
                amountWithoutTax: amountWithoutTax.toString(),
                tax_amount: taxAmount.toString(),
                amount: CheckAndReturn.roundToInteger(
                  totalAmount ?? 0
                ).toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                rate: "",
                amountWithoutTax: "0",
                tax_amount: "0",
                amount: "0",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          if (name === "qty") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              qty: "",
              tax_amount: "0",
              amount: "0",
            };
            const updatedItemList = itemList.map((item) =>
              item.id === id ? updatedItem : item
            );
            setItemList(updatedItemList);
          } else if (name === "rate") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              rate: "",
              tax_amount: "0",
              amount: "0",
            };
            const updatedItemList = itemList.map((item) =>
              item.id === id ? updatedItem : item
            );
            setItemList(updatedItemList);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscountChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        const selectedItem = itemList.find((item) => item.id === id);
        if (value.length > 0) {
          if (name === "discount") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                selectedItem.qty,
                value
              ));
              const totalAmount = CheckAndReturn.roundToInteger(Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                selectedItem.qty,
                value
              ));
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                discount: value,
                tax_amount: taxAmount.toString(),
                amount: CheckAndReturn.roundToInteger(
                  totalAmount ?? 0
                ).toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                discount: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          updatedItem = {
            ...itemList.find((item) => item.id === id),
            [name]: value,
            tax_amount: CheckAndReturn.roundToInteger(Calculation.GetDiscountTaxAmount(
              selectedItem.tax_rate,
              selectedItem.rate,
              selectedItem.qty,
              value
            )),
            amount: CheckAndReturn.roundToInteger(Calculation.GetDiscountTotalAmount(
              selectedItem.tax_rate,
              selectedItem.rate,
              selectedItem.qty,
              value
            )),
          };
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemarksChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        const selectedItem = itemList.find((item) => item.id === id);
        if (value) {
          if (name === "remarks") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                remarks: value,
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                remarks: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          updatedItem = {
            ...itemList.find((item) => item.id === id),
            [name]: value,
          };
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCustomerInputChange = (event) => {
    try {
      const customerId = event.target.value;
      const selectedCustomer = customers.find((item) => item.id === customerId);
      if (selectedCustomer !== null && selectedCustomer !== undefined) {
        setInvoice((prev) => ({
          ...prev, // Copy existing fields
          customer_id: selectedCustomer.id, // Update the specific field with the new value
          contact_name: selectedCustomer.fname,
          buyeraddress: selectedCustomer.address,
          date: isEdit ? invoice.date : Calculation.GetCurrentDate(),
          due_date: isEdit
            ? invoice.due_date
            : Calculation.getCurrentDatePlusDays(
              selectedCustomer.credit_period ?? 30
            ),
          salesperson: selectedCustomer.salesperson,
        }));
        fetchShipToAddress(selectedCustomer.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoiceCustomer = (event) => {
    try {
      const { name, value } = event.target;

      //Update form
      setInvoice((prev) => ({
        ...prev, //copy existing field values
        [name]: value, //update field value
      }));
      if (name === "date") {
        const selectedCustomer = customers.find(
          (item) => item.id === invoice.customer_id
        );
        const dueDate = Calculation.addDays(
          value,
          selectedCustomer ? selectedCustomer.credit_period : 30
        );
        setInvoice((prev) => ({
          ...prev,
          due_date: dueDate,
        }));
      }
      if (name === "ShipToAddress") {
        compareStateCodes(value);
      }

      // Reset the validation error when the user starts typing
      if (invoiceError[name]) {
        setInvoiceError((prevValidationError) => ({
          ...prevValidationError,
          [name]: "",
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const stateCodeByName = async (stateName) => {
  //   try {
  //     const result = await configServ.getStateCodeByname({ State: stateName });
  //     // setShipToAddresses(result)
  //     return result;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const compareStateCodes = async () => {
    try {
      dispatch(triggerLoader());
      const dataToSend = {
        cust_id: invoice.customer_id,
        address: invoice.ShipToAddress,
      };
      const state = await configServ.getStateByCidAddress(dataToSend);
      const company = await configServ.company_details({
        company_id: company_id,
      });
      if (state.State && company.State) {
        if (Boolean(state.State.toLowerCase() === company.State.toLowerCase())) {
          setInvoice((state) => ({
            ...state,
            tax_type: "CGST+SGST",
          }));
        } else {
          setInvoice((state) => ({
            ...state,
            tax_type: "IGST",
          }));
        }
      }
    }
    catch (e) {
      console.log(e);
    }
    finally {
      dispatch(triggerLoader());
    }
  };

  // Handle the Autocomplete's onChange event
  // Updated handleAutocompleteChange to support keyboard navigation
  const handleAutocompleteChange = (event, value, id) => {
    if (value !== null && value !== undefined) {
      // Trigger the input change only when a valid value is selected
      handleInputChange({ target: { name: "itemname", value } }, id);
    }
  };

  // const handleFirmAutocompleteChange = (event) => {
  //     try {
  //         const value = event.target.innerText;
  //         if (value !== null && value !== undefined) {
  //             if (value.length > 0) {
  //                 handleFirmInputChange({ target: { name: 'fname', value } });
  //             }
  //         }
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // };

  const handleCustomerAutocompleteChange = (customer_name, value) => {
    try {
      if (value !== null && value !== undefined) {
        handleCustomerInputChange({
          target: { name: customer_name, value },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to validate the invoice fields
  const validateInvoice = () => {
    const errors = {};

    // Check if the required fields are empty
    if (!invoice.customer_id) {
      errors.customer_id = "Customer Name is required.";
    }
    if (!invoice.buyeraddress) {
      errors.buyeraddress = "Bill To Address is required.";
    }
    if (!invoice.ShipToAddress || invoice.ShipToAddress === "") {
      errors.ShipToAddress = "ShipTo Address is required.";
    }
    if (!isInvoiceNumber && !isEdit) {
      errors.invoice_number = "required";
    }
    if (!invoice.date) {
      errors.date = "Invoice Date is required.";
    }
    if (!invoice.due_date) {
      errors.due_date = "Invoice Due Date is required.";
    }

    setInvoiceError(errors);
    return errors;
  };

  // Validate ItemList
  const validateItemList = () => {
    const errors = itemList.map((item) => {
      if (!item.issizeAdded) {
        return {
          itemname: !item.itemname ? "Item Name is required." : "",
          qty: !item.qty ? "Quantity is required." : "",
          // Add additional validations for other item fields as needed...
        };
      } else {
        return {
          itemname: !item.itemname ? "Item Name is required." : "",
          qty: !item.qty ? "Quantity is required." : "",
          size_id: !item.size_id ? "Size is required." : "",
          // Add additional validations for other item fields as needed...
        };
      }
    });

    setItemListError(errors);
    const hasErrors = errors.some((error) => error.itemname || error.qty);
    return hasErrors ? errors : {};
  };

  const handleSubmit = async () => {
    try {
      dispatch(triggerLoader());
      const invoiceErrors = validateInvoice();
      const itemListErrors = validateItemList();
      if (
        Object.keys(invoiceErrors).length > 0 ||
        Object.keys(itemListErrors).length > 0
      ) {
        // Handle the errors as needed (e.g., show error messages, prevent saving, etc.)
        return false; // Return early if there are errors
      }

      invoice.is_credit_limit = is_credit_limit;
      invoice.item = await handleInvoiceItemList();

      const dataToSend = {
        ...invoice,
        user_id: user_id,
        company_id: company_id,
      };
      if (!isEdit) {
        const res = await configServ.createInvoice(dataToSend);
        if (res.status === 200) {
          handleReset();
          setOpen(true);
          const resData = res.data;
          const printInvData = {
            ...resData,
            user_id: user_id,
          };
          setInvoiceData(printInvData);
          setIsCreate(!isCreate);
          setIsChanged(!isChanged);
        } else if (res.status === 409) {
          setConfirmationMessage(res.message);
        } else {
          handleOpenDialog(
            "Error",
            "Invoice creation failed. Please try again.",
            "error"
          );
        }
      } else {
        const res = await configServ.editInvoice(dataToSend);
        if (res.status === 200) {
          handleReset();
          setOpen(true);
          const resData = res.data;
          const printInvData = {
            ...resData,
            user_id: user_id,
          };
          setInvoiceData(printInvData);
          setIsCreate(!isCreate);
          setIsChanged(!isChanged);
        } else {
          handleOpenDialog(
            "Error",
            "Invoice updation failed. Please try again.",
            "error"
          );
        }
      }
    } catch (error) {
      console.log(error);
      handleOpenDialog("Error", "An unexpected error has occurred.", "error");
    } finally {
      dispatch(triggerLoader());
    }
  };

  const handleInvoiceItemList = async () => {
    try {
      const invoiceItems = itemList.map((item) => ({
        itemname: item.itemname,
        item_id: item.item_id,
        itemcode: item.itemcode,
        packagingunit: item.packagingunit,
        hsn_code: item.hsn_code,
        tax_rate: item.tax_rate,
        qty: item.qty,
        rate: item.rate,
        discount: item.discount,
        tax_amount: item.tax_amount,
        amount: item.amount,
        size_id: item.size_id,
        issizeAdded: item.issizeAdded,
        remarks: item.remarks,
      }));
      return invoiceItems;
    } catch (error) {
      console.log(error);
    }
  };

  const calculateTotalQuantity = () => {
    let totalQty = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const quantity = parseInt(item.qty);
      // Check if the amount is a valid number
      if (!isNaN(quantity)) {
        // Add the amount to the total
        totalQty += quantity;
      }
    });
    setTotalQuantity(totalQty);
  };

  const calculateTotalTaxAmount = () => {
    let totalTaxAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const taxAmount = parseFloat(item.tax_amount);
      // Check if the amount is a valid number
      if (!isNaN(taxAmount)) {
        // Add the amount to the total
        totalTaxAmount += taxAmount;
      }
    });
    setTotalTaxAmount(totalTaxAmount.toFixed(2));
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const amount = parseFloat(item.amount);
      // Check if the amount is a valid number
      if (!isNaN(amount)) {
        // Add the amount to the total
        totalAmount += amount;
      }
    });
    setTotalAmount(totalAmount.toFixed(2));
  };

  // Function to reset all form fields and state
  const handleReset = () => {
    setAutoGenerate(true);
    setTotalTaxAmount(0);
    setTotalAmount(0);
    setInvoice(invoiceInitial);
    setInvoiceError(invoiceErrorInitial);
    setItemList(itemListInitial);
    setItemListError([]);
    setEditData({});
    setIsEdit(false);
    setIs_credit_limit(true);
  };

  useEffect(() => {
    if (invoice.ShipToAddress) {
      compareStateCodes();
    }
  }, [invoice.ShipToAddress]);

  const handleOTI = () => {
    setOti(!oti);
  };

  const handlePopup = () => {
    try {
      if (isCreate === true) {
        handleReset();
      }
      setIsCreate(!isCreate);
      dispatch(rSetShowFab(isCreate));
    } catch (error) {
      console.log(error);
    }
  };

  const checkInvoiceNumber = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        invoice_number: invoice.invoice_number,
      };
      const res = await configServ.checkInvoiceNumber(dataToSend);
      if (res.status === 200) {
        setIsInvoiceNumber(true);
      } else {
        setIsInvoiceNumber(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (invoice.invoice_number) {
      checkInvoiceNumber();
    } else {
      setIsInvoiceNumber(true);
    }
  }, [invoice.invoice_number]);

  const handleConfirm = async (response) => {
    if (response === true) {
      setIs_credit_limit(false);
    } else {
      setIs_credit_limit(true);
    }
  };

  useEffect(() => {
    if (is_credit_limit === false) {
      handleSubmit();
    }
  }, [is_credit_limit]);

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title={title}
        message={dialogContent}
        buttonTitle="Ok"
        buttonType={buttonType}
      />

      <WantToPrint open={open} setOpen={setOpen} data={invoiceData} />

      <ConfirmationDialog
        message={confirmationMessage}
        onClose={() => setConfirmationMessage(null)}
        onConfirm={handleConfirm}
      />

      <CardHeadMenu
        prefix={"Inv"}
        suffix={"Order"}
        title={"INVOICE"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        handleOTI={handleOTI}
        create={isCreate}
      />

      <OrderToInvoice
        handleClose={handleOTI}
        open={oti}
        sendData={setInvoice}
        sendItems={setItemList}
        invoiceInitial={invoiceInitial}
        itemListInitial={itemListInitial}
      />

      {isCreate === true && (
        <>
          <motion.div
            style={{
              width: "100%",
              backgroundColor: "#e3f3fc",
              padding: 20,
              paddingLeft: 0,
              borderRadius: "10px",
              border: "none",
              margin: "10px",
            }}
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              style={{ padding: 10 }}
              spacing={1}
            >
              <Grid item xs={6} md={6}>
                <Link to="/account-master-entry/customers">
                  <Tooltip title={"Add customer"}>
                    <PersonAddIcon
                      sx={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "grey",
                        margin: "2px",
                      }}
                      justifyContent="flex-end"
                    />
                  </Tooltip>
                </Link>
              </Grid>
              <Grid
                container
                item
                xs={6}
                md={6}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Stack direction={"row"} spacing={1}>
                  <IconButton
                    onClick={handleReset}
                    sx={{
                      borderRadius: 2,
                      px: 2, // Horizontal padding
                      py: 1, // Vertical padding
                      backgroundColor: "secondary.main", // Use theme secondary color
                      color: "white", // Icon color
                      "&:hover": {
                        backgroundColor: "secondary.dark", // Darker shade on hover
                      },
                      ml: 1, // Margin left to separate from other elements
                    }}
                    title="Reset"
                  >
                    <RotateLeftIcon sx={{ fontSize: 16 }} />
                  </IconButton>

                  <IconButton
                    size="small"
                    onClick={handleSubmit}
                    sx={{
                      borderRadius: 2,
                      px: 2, // Horizontal padding
                      py: 1, // Vertical padding
                      backgroundColor: isEdit ? "success.main" : "primary.main", // Use theme primary color
                      color: "white", // Icon color
                      "&:hover": {
                        backgroundColor: isEdit
                          ? "success.dark"
                          : "primary.dark", // Darker shade on hover
                      },
                    }}
                    title={isEdit ? "Update" : "Save"}
                  >
                    {<SaveIcon sx={{ fontSize: 16 }} />}
                  </IconButton>
                </Stack>
              </Grid>
              <Grid item xs={12} md={3}>
                <Autocomplete
                  disablePortal
                  fullWidth
                  size="small"
                  id="customer_id"
                  name="customer_id"
                  options={customers}
                  getOptionLabel={(option) => option.fname}
                  sx={{ background: "white" }}
                  value={
                    customers.find(
                      (customer) => customer.id === invoice.customer_id
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleCustomerAutocompleteChange(
                        "customer_id",
                        newValue.id
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Firm Name"
                      name="customer_id"
                      onChange={handleCustomerInputChange}
                      error={!!invoiceError.customer_id}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="buyeraddress"
                  value={invoice.buyeraddress}
                  onChange={handleInvoiceCustomer}
                  label="Bill To Address"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.buyeraddress}
                  InputLabelProps={{
                    shrink: invoice.buyeraddress ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Ship To Address
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "white" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ship To Address"
                    name="ShipToAddress"
                    value={invoice.ShipToAddress}
                    onChange={handleInvoiceCustomer}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {shipToAddresses.map((item) => (
                      <MenuItem key={item.id} value={item.address}>
                        {item.address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="Invoice Date"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="date"
                  value={invoice.date}
                  onChange={handleInvoiceCustomer}
                  InputLabelProps={{ shrink: true }}
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.date}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="Invoice Due Date"
                  name="due_date"
                  value={invoice.due_date}
                  onChange={handleInvoiceCustomer}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.due_date}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Sales Order No"
                  variant="outlined"
                  name="sales_order"
                  value={invoice.sales_order}
                  onChange={handleInvoiceCustomer}
                  type="text"
                  fullWidth
                  size="small"
                  disabled
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Salesperson/Ref. Name"
                  variant="outlined"
                  name="salesperson"
                  value={invoice.salesperson || ""}
                  onChange={handleInvoiceCustomer}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  sx={{ backgroundColor: "white" }}
                >
                  <InputLabel id="pay_mode-label">Payment Mode</InputLabel>
                  <Select
                    labelId="pay_mode-label"
                    id="pay_mode"
                    name="pay_mode"
                    value={invoice.pay_mode || ""}
                    onChange={handleInvoiceCustomer}
                    label="Payment Mode"
                  >
                    <MenuItem value="Weekly">Weekly</MenuItem>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="One-Time">One-Time</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={isEdit}
                  label="Invoice No"
                  variant="outlined"
                  name="invoice_number"
                  value={invoice.invoice_number}
                  onChange={handleInvoiceCustomer}
                  type="text"
                  fullWidth
                  size="small"
                  InputLabelProps={{
                    shrink: invoice.invoice_number ? true : false,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {isInvoiceNumber || isEdit ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.invoice_number}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  label="TCS(%)"
                  variant="outlined"
                  name="tcs_rate"
                  value={invoice.tcs_rate}
                  onChange={handleInvoiceCustomer}
                  type="text"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Delivery Note"
                  name="delivery_note"
                  value={invoice.delivery_note}
                  onChange={handleInvoiceCustomer}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  rows={2}
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      sx={{ fontWeight: "bolder", marginLeft: "20px" }}
                    >
                      Tax in
                    </FormLabel>
                    <RadioGroup
                      name="use-radio-group"
                      row
                      sx={{ marginLeft: "20px" }}
                    >
                      <FormControlLabel
                        checked={invoice.tax_type === "CGST+SGST"}
                        value="CGST_SGST"
                        label="CGST + SGST"
                        control={
                          <Radio
                            size="small"
                            onClick={() =>
                              setInvoice((prev) => ({
                                ...prev,
                                tax_type: "CGST+SGST",
                              }))
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        checked={invoice.tax_type === "IGST"}
                        value="IGST"
                        label="IGST"
                        control={
                          <Radio
                            size="small"
                            onClick={() =>
                              setInvoice((prev) => ({
                                ...prev,
                                tax_type: "IGST",
                              }))
                            }
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <AddInvoiceItems
              itemList={itemList}
              handleAutocompleteChange={handleAutocompleteChange}
              handleInputChange={handleInputChange}
              handleAddItem={handleAddItem}
              handleDeleteItem={handleDeleteItem}
              items={items}
              handleQuantityChange={handleQuantityChange}
              handleDiscountChange={handleDiscountChange}
              handleRemarksChange={handleRemarksChange}
              errors={itemListError}
            />

            <Grid container alignItems={"center"} spacing={1}>
              <Grid item xs={12} md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ marginTop: "10px", color: "green" }}
                >
                  Quantity: {totalQuantity}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ marginTop: "10px", color: "green" }}
                >
                  Tax Amount: ₹{CheckAndReturn.roundToInteger(totalTaxAmount)}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ marginTop: "10px", color: "green" }}
                >
                  Gross Amount: ₹{CheckAndReturn.roundToInteger(totalAmount)}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                md={12}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Stack direction={"row"} spacing={1}>
                  <IconButton
                    size="small"
                    onClick={handleSubmit}
                    sx={{
                      borderRadius: 2,
                      px: 2, // Horizontal padding
                      py: 1, // Vertical padding
                      backgroundColor: isEdit ? "success.main" : "primary.main", // Use theme primary color
                      color: "white", // Icon color
                      "&:hover": {
                        backgroundColor: isEdit
                          ? "success.dark"
                          : "primary.dark", // Darker shade on hover
                      },
                    }}
                    title={isEdit ? "Update" : "Save"}
                  >
                    {<SaveIcon sx={{ fontSize: 22 }} />}
                  </IconButton>
                </Stack>
              </Grid>
            </Grid>
          </motion.div>
        </>
      )}
    </>
  );
}

export default memo(InvoiceCard);
