import React, { useState, useEffect, memo } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import { appServiceName } from "../../../services/http";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Grid,
  Typography,
  Button,
  NativeSelect,
  InputLabel,
  TextField,
  InputAdornment,
  FormControl,
  CardContent,
  CircularProgress,
  Card,
  CardActions,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import configServ from "../../../services/config";
import CompanyPopup from "../../Dialog/CompanyPopup";
import useDeviceDetect from "../../Detect/useDeviceDetect ";

//FUNCTION
const RegistrationTable = ({
  handleEditData,
  isChanged,
  pagination = true,
}) => {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [companyDataList, setCompanyDataList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [companyList, setCompanyList] = useState([]);
  const [company, setCompany] = useState(null);
  const [companyOpen, setCompanyOpen] = useState(false);
  const [filterCompanyList, setFilterCompanyList] = useState([]);
  const [searchBy, setSearchBy] = useState("CompanyName");
  const [loading, setLoading] = useState(true);
  const { isMobile } = useDeviceDetect();

  const headers = [
    "COMPANY NAME",
    "COMPANY TYPE",
    "PHONE",
    "ADDRESS",
    "SESSION",
    "STATUS",
    "ACTION",
  ];

  useEffect(() => {
    fetchCompanyList();
  }, [isChanged]);

  const fetchCompanyList = async () => {
    try {
      const res = await configServ.getCompanyList({ user_id: user_id });
      if (res.length > 0) {
        setCompanyList(res);
        setFilterCompanyList(res);
        setCount(res.length);
      }
    } catch (error) {
      console.log(error);
      setCompanyDataList([]);
      setCompanyList([]);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  const paginatedData = pagination
    ? filterCompanyList.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      )
    : filterCompanyList;

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5", // Set the header background color to blue
    color: "white",
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };
  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === "") {
        data = companyList;
      } else if (searchBy === "CompanyName") {
        data = companyList.filter((x) =>
          x.CompanyName.toLowerCase().includes(search.toLowerCase())
        );
      } else if (searchBy === "CompanyType") {
        data = companyList.filter((x) =>
          x.CompanyType.toLowerCase().includes(search.toLowerCase())
        );
      } else if (searchBy === "MobileNo") {
        data = companyList.filter((x) =>
          x.MobileNo.toLowerCase().includes(search.toLowerCase())
        );
      }
      setFilterCompanyList(data);
      setCount(data.length);
    } catch (error) {
      console.log(error);
    }
  };

  const printInvoice = async (invoice_no) => {
    const data = {
      invoice_no: invoice_no,
      user_id: user_id,
      company_id: company_id,
    };
    try {
      const fileName = `invoice${user_id}${invoice_no}`;
      const url = "/api/print_invoice/";
      axios
        .post(`${appServiceName}${url}`, data, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          const blobFile = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blobFile);
          window.open(url);
          // console.log(url)
          // link.href = url;
          // const link = document.createElement('a');
          // link.download = fileName;
          // link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrint = (params) => {
    const data = companyList.filter((x) => x.id === params.id);
    printInvoice(data[0].invoice_number);
  };

  const handleEdit = (params) => {
    scrollToTop();
    const data = companyList.filter((x) => x.id === params.id);
    handleEditData(data);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCompanyPopup = (params) => {
    setCompanyOpen(true);
    setCompany(params);
  };

  const handleCompanyClose = () => {
    setCompanyOpen(false);
    setCompany(null);
  };

  //#endregion

  return (
    <>
      {company && (
        <CompanyPopup
          open={companyOpen}
          handleClose={handleCompanyClose}
          data={company}
        />
      )}

      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ p: 0, mt: 2 }}
        spacing={1}
        direction={"row"}
      >
        <Grid item xs={12} md={6} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Company List:{count}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
            <FormControl fullWidth>
              <InputLabel variant="standard" htmlFor="uncontrolled-native">
                Search By
              </InputLabel>
              <NativeSelect
                defaultValue={"contact_name"}
                onChange={handleSearchBy}
                inputProps={{
                  name: "searchBy",
                  id: "uncontrolled-native",
                }}
              >
                <option value={"CompanyName"}>Company Name</option>
                <option value={"CompanyType"}>Company Type</option>
                <option value={"MobileNo"}>Mobile</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={3} justifyContent={"flex-end"}>
          <TextField
            id="outlined-basic"
            size="small"
            fullWidth
            placeholder="search"
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fill: "grey" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {isMobile ? (
        <>
          {" "}
          <Grid container spacing={2} mt={1}>
            {loading ? (
              <Grid item xs={12} textAlign="center">
                <CircularProgress />
              </Grid>
            ) : (
              paginatedData.map((row, index) => (
                <Grid item xs={12} key={index}>
                  <Card variant="outlined">
                    <CardContent>
                      <Typography variant="h6">
                       <strong>S.No.</strong> {page * rowsPerPage + index + 1}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Company Name:</strong> . {row.CompanyName}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Type:</strong> {row.CompanyType}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Mobile: </strong>{row.MobileNo}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Address:</strong> {row.Address}
                      </Typography>
                      <Typography variant="body2">
                       <strong> Session:</strong>{" "}
                        {row.session ? (
                          row.session.session
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Typography>
                      <Typography variant="body2">
                       <strong> Status:</strong>{" "}
                        {row.is_active === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Tooltip title="Edit" arrow>
                        <IconButton
                          onClick={() => {
                            handleEdit(row);
                            scrollToTop();
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Detail" arrow>
                        <IconButton onClick={() => handleCompanyPopup(row)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    </CardActions>
                  </Card>
                </Grid>
              ))
            )}
            {pagination && (
              <Grid item xs={12} textAlign="center">
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <TableContainer component={Paper} sx={{ my: 1 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={headerCellStyle}>S.NO.</TableCell>
                  {headers.map((header, index) => (
                    <TableCell key={index} style={headerCellStyle}>
                      {header}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    <Box>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                <TableBody>
                  {paginatedData.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                      <TableCell>{row.CompanyName}</TableCell>
                      <TableCell>{row.CompanyType}</TableCell>
                      <TableCell>{row.MobileNo}</TableCell>
                      <TableCell>{row.Address}</TableCell>
                      <TableCell>
                        {row.session ? (
                          row.session.session
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.is_active === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Edit" arrow>
                          <IconButton
                            sx={{
                              borderRadius: 10,
                              width: "fit-content",
                              transition: "100ms",
                              cursor: "pointer",
                              padding: 0.5,
                              ":hover": { backgroundColor: "#99999955" },
                            }}
                            onClick={() => {
                              handleEdit(row);
                              scrollToTop();
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Detail" arrow>
                          <IconButton
                            sx={{
                              borderRadius: 10,
                              width: "fit-content",
                              transition: "100ms",
                              cursor: "pointer",
                              padding: 0.5,
                              ":hover": { backgroundColor: "#99999955" },
                            }}
                            onClick={() => handleCompanyPopup(row)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}>
                      <TableCell colSpan={headers.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
            {pagination && (
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            )}
          </TableContainer>
        </>
      )}
    </>
  );
};

export default memo(RegistrationTable);
