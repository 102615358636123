import React, { memo, useEffect, useState } from 'react';
import {
    Button,
    MenuItem,
    FormControl,
    InputLabel,
    Grid,
    FormControlLabel,
    Checkbox,
    Typography,
    Select,
    TextField,
    Autocomplete,
} from "@mui/material";
import { motion } from "framer-motion";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import Calculation from '../../../utility/Calculations';
import configServ from '../../../services/config';
import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';
import AlertDialog from '../../AlertDialog/AlertDialog';
import AdminSubHeader from '../../header/subHeader/AdminSubHeader';
import CheckAndReturn from '../../../utility/CheckAndReturn';

//FUNCTION
const DispatchCard = ({ editData, setEditData, isChanged, setIsChanged }) => {
    //#region code
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const { user_id, company_id, is_super } = useSelector((state) => state.GlobalVariables);

    const formDataInitial = {
        Date: Calculation.GetCurrentDate()
    }
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [invoices, setInvoices] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [title, setTitle] = useState('Alert');
    const [dialogContent, setDialogContent] = useState('');
    const [buttonType, setButtonType] = useState(null);

    useEffect(() => {
        fetchInvoice();
    }, []);

    useEffect(() => {
        if (editData !== null) {
            setFormData(editData);;
            setIsEdit(true);
            setIsCreate(true)
        }
    }, [editData]);

    const handleOpenDialog = (heading, content, type) => {
        setTitle(heading);
        setDialogContent(content);
        setButtonType(type);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setTitle('Alert');
        setDialogContent('');
        setButtonType(null);
    };

    const handlePopup = () => {
        try {
            if (isCreate === true) {
                handleReset();
            }
            setIsCreate(!isCreate);
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleInvoice = (event) => {
        try {
            const { name, value } = event.target;

            if (value === null) {
                setFormData((prev) => ({
                    ...prev,
                    [name]: '',
                    qnty: '',
                    amount: ''
                }));
            }
            else {
                // Find the invoice with the given invoice_number
                const inv = invoices.filter(item => item.invoice_number === value)[0];

                let quanity = 0;
                if (inv && inv.items.length > 0) {
                    // Use reduce to sum up the qty for all items
                    quanity = inv.items.reduce((total, item) => {
                        return total + (item.qty ? parseFloat(item.qty) : 0); // Convert qty to a number and add to total
                    }, 0);
                }

                //Update form
                setFormData((prev) => ({
                    ...prev,
                    [name]: value,
                    qnty: quanity,
                    amount: inv.total_amount
                }));

                // Reset the validation error when the user starts typing
                if (validationErrors[name]) {
                    setValidationErrors((prevValidationError) => ({
                        ...prevValidationError,
                        [name]: '',
                        qnty: '',
                        amount: ''
                    }));
                }
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleFormData = (event) => {
        try {
            const { name, type, value, checked } = event.target;

            //Update form
            setFormData((prev) => ({
                ...prev,
                [name]: type === "checkbox" ? checked : value
            }));

            // Reset the validation error when the user starts typing
            if (validationErrors[name]) {
                setValidationErrors((prevValidationError) => ({
                    ...prevValidationError,
                    [name]: '',
                }));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchInvoice = async () => {
        try {
            const dataToSend = {
                company_id: company_id
            }
            const res = await configServ.getInvoiceByCompany(dataToSend);
            if (res.status === 200) {
                setInvoices(res.data);
            }
            else {
                setInvoices([]);
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const validate = async () => {
        try {
            const errors = {};
            if (!formData.invoice_no) {
                errors.invoice_no = 'required.'
            }
            if (!formData.transporter_name) {
                errors.transporter_name = 'required.'
            }
            if (!formData.TransporterMobileNo) {
                errors.TransporterMobileNo = 'required.'
            }
            if (!formData.qnty) {
                errors.qnty = 'required.'
            }
            if (!formData.amount) {
                errors.amount = 'required.'
            }
            if (!formData.Date) {
                errors.Date = 'required';
            }
            if (Boolean(formData.is_ewaybill)) {
                if (CheckAndReturn.checkError(formData.eWayBillNo)) {
                    errors.eWayBillNo = 'required.'
                }
                if (!formData.ewaybill_date) {
                    errors.ewaybill_date = 'required.'
                }
                if (!formData.ewaybill_validity) {
                    errors.ewaybill_validity = 'required';
                }
            }
            setValidationErrors(errors);

            return Object.values(errors).every(error => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const handleSubmit = async (e) => {
        try {
            dispatch(triggerLoader());
            e.preventDefault();
            const isValid = await validate();
            if (isValid === false) {
                return false;
            }
            const dataToSend = {
                ...formData,
                company_id: company_id
            }
            if (!isEdit) {
                const res = await configServ.createDispatch(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog('Success', res.message || "Dispatched successfully.", 'success');
                    handleReset()
                }
                else {
                    handleOpenDialog('Error', res.message || "Dispatch failed. Please try again.", 'error');
                }
            }
            else {
                const res = await configServ.updateDispatch(dataToSend);
                if (res.status === 200) {
                    setIsChanged(!isChanged)
                    handleOpenDialog('Success', res.message || "Dispatched updated successfully.", 'success');
                    handleReset()
                }
                else {
                    handleOpenDialog('Error', res.message || "Dispatch failed. Please try again.", 'error');
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            dispatch(triggerLoader());
        }
    }

    const handleReset = () => {
        try {
            setFormData({});
            setValidationErrors({});
            setEditData(null);
            setIsEdit(false);
        }
        catch (e) {
            console.log(e);
        }
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title={title}
                message={dialogContent}
                buttonTitle="Ok"
                buttonType={buttonType}
            />
            <AdminSubHeader
                prefix={"Inv"}
                suffix={"Order"}
                title={"DISPATCH"}
                handlePopup={handlePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {isCreate === true && (
                <>
                    <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.1, delay: 0.1 }}
                    >
                        <form onSubmit={handleSubmit} onReset={handleReset}>
                            <Grid
                                container
                                alignItems="flex-end"
                                justifyContent="flex-start"
                                sx={{ p: 0, }}
                                spacing={1}
                            >
                                <Grid item xs={12} m={0} p={0} fontWeight={600}>
                                    Invoice Detail
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Autocomplete
                                        //disablePortal
                                        fullWidth
                                        size='small'
                                        id="combo-box-demo"
                                        name='invoice_no'
                                        options={invoices.map((option) => option.invoice_number)}
                                        sx={{ background: 'white' }}
                                        value={CheckAndReturn.checkTextNullAndUndefined(formData.invoice_no)}
                                        onChange={(event, newValue) => handleInvoice({ target: { name: 'invoice_no', value: newValue } })}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Invoice No" value={CheckAndReturn.checkTextNullAndUndefined(formData.invoice_no)} />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Quantity'}
                                        name='qnty'
                                        value={CheckAndReturn.checkTextNullAndUndefined(formData.qnty)}
                                        onChange={handleFormData}
                                        error={!!validationErrors.qnty}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Amount'}
                                        name='amount'
                                        value={CheckAndReturn.checkTextNullAndUndefined(formData.amount)}
                                        onChange={handleFormData}
                                        error={!!validationErrors.amount}
                                    />
                                </Grid>

                                <Grid item xs={12} m={0} p={0} fontWeight={600}>
                                    Transporter Detail
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transporter Name'}
                                        name='transporter_name'
                                        value={formData.transporter_name || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.transporter_name}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Phone'}
                                        name='TransporterMobileNo'
                                        value={formData.TransporterMobileNo || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.TransporterMobileNo}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Vehicle No'}
                                        name='vehicle_num'
                                        value={formData.vehicle_num || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transport Mode'}
                                        name='TransportMode'
                                        value={CheckAndReturn.checkTextNullAndUndefined(formData.TransportMode)}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='number'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Transportation Cost'}
                                        name='transportationCost'
                                        value={CheckAndReturn.checkTextNullAndUndefined(formData.transportationCost)}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='text'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Distance'}
                                        name='Distance'
                                        value={formData.Distance || ""}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        fullWidth
                                        size='small'
                                        type='date'
                                        sx={{ backgroundColor: 'white' }}
                                        label={'Dispatch Date'}
                                        name='Date'
                                        InputLabelProps={{ shrink: true }}
                                        value={formData.Date || ""}
                                        onChange={handleFormData}
                                        error={!!validationErrors.Date}
                                    />
                                </Grid>

                                {
                                    Boolean(formData.is_ewaybill) === true &&
                                    <>
                                        <Grid item xs={12} m={0} p={0} fontWeight={600}>
                                            E-way Bill Detail
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                type='text'
                                                sx={{ backgroundColor: 'white' }}
                                                label={'Eway Bill No'}
                                                name='eWayBillNo'
                                                value={CheckAndReturn.checkTextNullAndUndefined(formData.eWayBillNo)}
                                                onChange={handleFormData}
                                                error={!!validationErrors.eWayBillNo}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                type='date'
                                                sx={{ backgroundColor: 'white' }}
                                                label={'Eway Bill Date'}
                                                name='ewaybill_date'
                                                InputLabelProps={{ shrink: true }}
                                                value={CheckAndReturn.checkTextNullAndUndefined(formData.ewaybill_date)}
                                                onChange={handleFormData}
                                                error={!!validationErrors.ewaybill_date}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <TextField
                                                fullWidth
                                                size='small'
                                                type='date'
                                                sx={{ backgroundColor: 'white' }}
                                                label={'Eway Bill Validity'}
                                                name='ewaybill_validity'
                                                InputLabelProps={{ shrink: true }}
                                                value={CheckAndReturn.checkTextNullAndUndefined(formData.ewaybill_validity)}
                                                onChange={handleFormData}
                                                error={!!validationErrors.ewaybill_validity}
                                            />
                                        </Grid>
                                    </>
                                }

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!formData.is_ewaybill}
                                                onChange={handleFormData}
                                            />
                                        }
                                        name="is_ewaybill"
                                        label="Eway bill"
                                        sx={{
                                            //ml: 2,
                                            fontSize: { xs: "14px", md: "10px" },
                                        }}
                                    />
                                </Grid>
                                <Grid container item xs={12}>
                                    {/* <Grid container item xs={12} md={6} justifyContent={'flex-start'}>
                                        <FormControlLabel
                                            control={<Checkbox />}
                                            name="status"
                                            checked={Boolean(formData.status)}
                                            onChange={handleChangeCheckbox}
                                            label="Status"
                                            sx={{ fontWeight: "bold" }}
                                        />
                                    </Grid> */}
                                    <Grid container item xs={12} md={12} justifyContent={{ xs: 'center', md: 'flex-end' }}>
                                        <Button
                                            type='reset'
                                            color={'secondary'}
                                            startIcon={<RotateLeftIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            Reset
                                        </Button>
                                        <Button
                                            type='submit'
                                            color={isEdit ? 'success' : 'primary'}
                                            startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                            variant='contained'
                                            sx={{ mx: 1, cursor: 'pointer' }}
                                        >
                                            {isEdit ? 'Update' : 'Save'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    </motion.div>
                </>
            )}
        </>
    )
}

export default memo(DispatchCard);
