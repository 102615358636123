import React, { memo, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Checkbox,
  Box,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  CircularProgress,
  Stack,
  IconButton,
  Tooltip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useSelector } from "react-redux";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import configServ from "../../../services/config";
import AccountMasterPopup from "./AccountMasterPopup";
import useDeviceDetect from "../../../Components/Detect/useDeviceDetect ";

//FUNCTION
const AccountMasterTable = ({ handleEditData, isChanged, isEdit }) => {
  //#region code
  const { user_id, company_id, is_super } = useSelector(
    (state) => state.GlobalVariables
  );
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [accountList, setAccountList] = useState([]);
  const [filterAccountList, setFilterAccountList] = useState([]);
  const [accountClassified, setAccountClassified] = useState([]);
  const [searchBy, setSearchBy] = useState("accountname");
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [openAccountMaster, setOpenAccountMaster] = useState(false);
  const [accountMaster, setAccountMaster] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { isMobile } = useDeviceDetect();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchAccountClassified = async () => {
    try {
      const res = await configServ.getAccountClassified();
      if (res.status === 200) {
        setAccountClassified(res.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchAccountClassified();
  }, []);

  useEffect(() => {
    fetchAccountMasterList();
  }, [isChanged]);

  const fetchAccountMasterList = async () => {
    try {
      const dataToSend = {
        is_super: is_super,
        user_id: user_id,
        company_id: company_id,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
      };
      setLoading(true);
      const res = await configServ.getAccountMasterList(dataToSend);
      if (res.status === 200) {
        setAccountList(res.data);
        setFilterAccountList(res.data);
        setCount(res.total);
      } else {
        setAccountList([]);
        setFilterAccountList([]);
        setCount(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const headers = [
    "Account Name",
    "Classified",
    "Opening",
    "Balance",
    "Action",
  ];

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5", // Set the header background color to blue
    color: "white",
  };

  const cellStyle = {
    p: 0,
    px: 2,
    my: 0,
    //textAlign: 'center',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setFilterAccountList(accountList);
        setCount(accountList.length);
        setSearch(null);
      } else {
        const filteredData = accountList.filter((x) =>
          x.accountname.toLowerCase().includes(data.toLowerCase())
        );
        setFilterAccountList(filteredData);
        setCount(filteredData.length);
        setSearch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleEdit = (params) => {
    const data = accountList.filter((x) => x.id === params);
    handleEditData(data);
    scrollToTop();
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleAccountClassified = (params) => {
    try {
      const classifiedRow = accountClassified.find(
        (x) => x.PK.toString() === params.toString()
      );
      if (classifiedRow) {
        return classifiedRow.accountclassified;
      } else {
        return "N/A";
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAccounMaster = async (params) => {
    try {
      const dataToSend = {
        company_id: company_id,
        account: params.id,
      };
      const res = await configServ.getAccountTransactions(dataToSend);
      if (res.status === 200) {
        setOpenAccountMaster(true);
        setAccountMaster(res.data);
        setSelectedAccount(params);
      } else {
        setOpenAccountMaster(false);
        setAccountMaster([]);
        setSelectedAccount(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const closeAccountMaster = () => {
    setOpenAccountMaster(false);
    setAccountMaster([]);
    setSelectedAccount(null);
  };

  //#endregion

  return (
    <>
      <AccountMasterPopup
        openAccountMaster={openAccountMaster}
        closeAccountMaster={closeAccountMaster}
        accountMaster={accountMaster}
        selectedAccount={selectedAccount}
      />

      <Paper sx={{ width: "100%" }}>
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          sx={{ mb: 1 }}
          spacing={1}
          direction={"row"}
        >
          <Grid item xs={12} md={6} justifyContent={"flex-start"}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Account List: {count}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={"contact_name"}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "searchBy",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"accountname"}>Account Name</option>
                  <option value={"contactName"}>Name</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} justifyContent={"flex-end"}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: "grey" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        {isMobile ? (
          <>
            <Grid container spacing={2}>
              {loading ? (
                <Grid item xs={12} textAlign="center">
                  <Box>
                    <CircularProgress />
                  </Box>
                </Grid>
              ) : filterAccountList.length > 0 ? (
                filterAccountList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Grid item xs={12} key={row.id}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Stack spacing={2}>
                          <Box sx={{ fontWeight: "bold" }}>
                            <strong>S.No.:</strong>  {index + 1}
                          </Box>
                          <Box>
                            <strong>Account Name:</strong> ₹
                            {row.accountname}
                          </Box>
                          <Box>
                            <strong>Account Classified:</strong>{" "}
                            {handleAccountClassified(row.accountclassified)}
                          </Box>
                          <Box>
                            <strong>Opening Balance:</strong> ₹
                            {row.openingbalance ?? 0}
                          </Box>
                          <Box>
                            <strong>Total Amount:</strong> ₹
                            {CheckAndReturn.makePositive(
                              CheckAndReturn.roundToInteger(
                                row.total_amount ?? 0
                              )
                            )}
                            {(row.total_amount ?? 0) > 0 ? (
                              <span style={{ marginLeft: 4, color: "red" }}>
                                Dr
                              </span>
                            ) : (
                              <span style={{ marginLeft: 4, color: "green" }}>
                                Cr
                              </span>
                            )}
                          </Box>
                          <Stack direction="row" spacing={1}>
                            <Tooltip title="Edit" arrow>
                              <IconButton onClick={() => handleEdit(row.id)}>
                                <EditIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="View" arrow>
                              <IconButton
                                onClick={() => handleAccounMaster(row)}
                              >
                                <VisibilityIcon color="secondary" />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      </Paper>
                    </Grid>
                  ))
              ) : (
                <Grid item xs={12} textAlign="center">
                  <Box sx={{ color: "red" }}>No record found.</Box>
                </Grid>
              )}
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </>
        ) : (
          <>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((header, index) => (
                      <TableCell key={index} style={headerCellStyle}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                      <Box>
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableBody>
                    {filterAccountList.length > 0 ? (
                      filterAccountList
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          return (
                            <TableRow
                              key={row.id}
                              sx={{
                                ...rowStyle,
                                ...(index % 2 === 0
                                  ? evenRowStyle
                                  : oddRowStyle),
                              }}
                            >
                              <TableCell sx={cellStyle}>{index + 1}</TableCell>
                              <TableCell sx={cellStyle}>
                                {row.accountname}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                {handleAccountClassified(row.accountclassified)}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                ₹{row.openingbalance ?? 0}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                ₹
                                {CheckAndReturn.makePositive(
                                  CheckAndReturn.roundToInteger(
                                    row.total_amount ?? 0
                                  )
                                )}
                                {(row.total_amount ?? 0) > 0 ? (
                                  <span style={{ marginLeft: 4, color: "red" }}>
                                    Dr
                                  </span>
                                ) : (
                                  <span
                                    style={{ marginLeft: 4, color: "green" }}
                                  >
                                    Cr
                                  </span>
                                )}
                              </TableCell>
                              <TableCell sx={cellStyle}>
                                <Stack direction={"row"}>
                                  <IconButton>
                                    <EditIcon
                                      color={"primary"}
                                      onClick={() => handleEdit(row.id)}
                                      sx={{
                                        borderRadius: 10,
                                        width: "fit-content",
                                        transition: "100ms",
                                        cursor: "pointer",
                                        padding: 0,
                                        ":hover": {
                                          backgroundColor: "#99999955",
                                        },
                                      }}
                                    />
                                  </IconButton>
                                  <IconButton>
                                    <VisibilityIcon
                                      color="secondary"
                                      onClick={() => handleAccounMaster(row)}
                                      sx={{
                                        borderRadius: 10,
                                        width: "fit-content",
                                        transition: "100ms",
                                        cursor: "pointer",
                                        padding: 0,
                                        ":hover": {
                                          backgroundColor: "#99999955",
                                        },
                                      }}
                                    />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                          <Box sx={{ color: "red" }}>No record found.</Box>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={count}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </>
  );
};

export default memo(AccountMasterTable);
