import React,{useState,useEffect} from "react";
import { 
    Dialog, DialogTitle, DialogContent, DialogContentText,DialogActions,Button,TableContainer,
    Table,TableHead,TableRow,TableCell,Stack,Typography,IconButton,TableBody
} from '@mui/material';
import { motion} from 'framer-motion';
import CloseIcon from '@mui/icons-material/Close';
import http from "../../../services/http";
import MiniLoader from '../MiniLoader/MiniLoader';
import configServ from "../../../services/config";
import { useSelector } from "react-redux";

const CustPOdetail = ({open, setOpen,id}) =>{

    const { company_type } = useSelector((state) => state.admin);
    const [NestedTableData, setNestedTableData] = useState([]);
    const [miniLoader, setMiniLoader] = useState(false)
    const CustId ={
      "order_id": id
    }

const compType = ()=>{
    return company_type !== 'service'?'QUANTITY':'VALIDITY'
}

    const columns = [
        {
          id: 'id',
          label: 'SR. NO',
          minWidth: 170
        },
        {
          id: 'itemname',
          label: 'ITEM NAME',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'size',
          label: 'SIZE',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'Taxrate',
          label: 'TAX RATE',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toLocaleString('en-US'),
        },
        {
          id: 'qty',
          label: compType(),
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
          id: 'rate',
          label: 'RATE',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
        {
          id: 'amount',
          label: 'AMOUNT',
          minWidth: 170,
          align: 'right',
          format: (value) => value.toFixed(2),
        },
      ];

    useEffect(()=>{
        fetchNestedTableData();
    },[])

      const handleClose = () => {
        setOpen(false);
      };

      const fetchNestedTableData = async()=>{
        setMiniLoader(true)
        try{
            const result = await configServ.order_details(CustId)
            setNestedTableData(result)
            setMiniLoader(false)
        }catch(err){
            console.log(err)
            setMiniLoader(false)
        }
    }

    return(
        <>
        
         <Dialog open={open} 
         onClose={handleClose}
          aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
         fullWidth={true}
         maxWidth='md'
        >
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{mt:2,ml:2, color:'text.secondary'}} variant='body2'></Typography>
            <IconButton onClick={handleClose}>
                <CloseIcon color='primary'/>
            </IconButton>
        </Stack>
        <DialogTitle>PO Details</DialogTitle>
        <DialogContent>
          {/* <DialogContentText> */}
          <TableContainer sx={{ maxHeight: 440 }}
          component={motion.div}
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 440 }}
          transition={{ duration: 0.3, delay: 0.2 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <TableCell
                    key={column.id}>
                    <b>{column.label}</b>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
            {NestedTableData
              .map((row,index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell sx={{minWidth:100}} key={index}>{++index}</TableCell>
                        <TableCell >{row.itemname}</TableCell>
                        <TableCell >{row.item_size || 'N/A'}</TableCell>
                        <TableCell >{row.Taxrate}</TableCell>
                        {/* <TableCell >{row.qty}{' '}{'Month(s)'}</TableCell> */}
                        <TableCell >{company_type === 'product'? row.qty : `${row.qty} Month(s)`}</TableCell>
                        <TableCell >{row.rate}</TableCell>     
                        <TableCell >{row.amount}</TableCell>      
                  </TableRow>
                );
              })}
                    {miniLoader && (<TableRow>
                        <TableCell align='center' colSpan={8}><MiniLoader/></TableCell>
                    </TableRow>)}
          </TableBody>
            </Table>
            </TableContainer>
          {/* </DialogContentText> */}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions> */}
      </Dialog>
     
        </>
    )
};
export default CustPOdetail;