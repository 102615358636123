import React, { memo } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  IconButton,
  Grid,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import AddIcon from "@mui/icons-material/Add";

//FUNCTION
function AddCardSales({
  itemList,
  handleAutocompleteChange,
  handleInputChange,
  handleAddItem,
  handleDeleteItem,
  items,
  errors,
  with_gst,
}) {
  return (
    <>
      <div>
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#eeeef0",
            padding: 0,
            borderRadius: "10px",
            border: "none",
            margin: "10px",
            display: "flex",
            justifyContent: "center",
          }}
          initial={{ opacity: 0, y: 15 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <div>
            <Grid container>
              <Link to="/master-entry/item">
                <Tooltip title={"Add item"}>
                  <AddIcon
                    sx={{
                      cursor: "pointer",
                      color: "white",
                      backgroundColor: "grey",
                      margin: "5px",
                    }}
                    justifyContent="flex-end"
                  />
                </Tooltip>
              </Link>
            </Grid>
            {itemList.map((item, index) => (
              <div key={item.id}>
                <Grid
                  container
                  alignItems={"center"}
                  spacing={{ xs: 1, md: 1, lg: 1 }}
                  key={item.id}
                  sx={{ my: 1 }}
                >
                  <Grid item xs={12} sm={6} md={3} columnSpacing={0}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      value={items.find(it => it.item_code === item.item_code) || null}  // Find the selected item by itemcode
                      options={items}
                      getOptionLabel={(option) => option.item_name ? `${option.item_name}` : ''}  // Ensure proper label display
                      isOptionEqualToValue={(option, value) => option.item_code === value.item_code}  // Compare options by itemcode
                      onChange={(event, newValue) => {
                        if (newValue) {
                          handleAutocompleteChange(newValue.item_name, item.id)
                        }
                        else {
                          handleAutocompleteChange('');  // Handle case where selection is cleared
                        }
                      }} // Pass newValue directly
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ backgroundColor: "white" }}
                          size="small"
                          label="Item Name"
                          variant="outlined"
                          name="itemname"
                          value={item.itemname || ''}
                          onChange={(e) => handleInputChange(e, item.id)}
                          InputLabelProps={{ ...params.InputProps }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              const value = e.target.value;
                              const selectedItem = items.find(it => it.item_name === value);
                              if (selectedItem) {
                                handleAutocompleteChange(selectedItem.item_name);  // Set name when pressing Enter
                              }
                            }
                          }}
                          error={
                            errors[`${index}`] !== undefined
                              ? !!errors[`${index}`].itemname
                              : false
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1.5}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      size="small"
                      label="Unit"
                      variant="outlined"
                      type="text"
                      fullWidth
                      name="unit"
                      value={item.unit}
                      InputProps={{ readOnly: true }}
                      InputLabelProps={{
                        shrink: item.unit ? true : false,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      size="small"
                      label="Qty"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="qty"
                      value={item.qty}
                      InputLabelProps={{
                        shrink: item.qty ? true : false,
                      }}
                      onChange={(e) => handleInputChange(e, item.id)}
                      error={
                        errors[`${index}`] !== undefined
                          ? !!errors[`${index}`].qty
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1.5}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      size="small"
                      label="Rate(₹)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="rate"
                      value={item.rate || ""}
                      onChange={(e) => handleInputChange(e, item.id)}
                    />
                  </Grid>
                  {Boolean(with_gst) === true && (
                    <Grid item xs={12} sm={6} md={1}>
                      <TextField
                        sx={{ backgroundColor: "white" }}
                        size="small"
                        label="Tax(%)"
                        variant="outlined"
                        type="number"
                        fullWidth
                        name="Taxrate"
                        value={item.Taxrate || ""}
                        onChange={(e) => handleInputChange(e, item.id)}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} sm={6} md={1}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      size="small"
                      label="disc(%)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="discount"
                      value={item.discount || ""}
                      onChange={(e) => handleInputChange(e, item.id)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1.5}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      size="small"
                      label="Amount(₹)"
                      variant="outlined"
                      type="number"
                      fullWidth
                      name="amount"
                      value={item.amount}
                      InputLabelProps={{ shrink: item.amount ? true : false }}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={1.5}>
                    <Tooltip title={"Add item"}>
                      <IconButton onClick={() => handleAddItem(item.id)}>
                        <AddBoxIcon fontSize="medium" sx={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Delete item"}>
                      <IconButton
                        hidden={itemList.length > 1 ? false : true}
                        onClick={() => handleDeleteItem(item)}
                      >
                        <DeleteIcon
                          fontSize="medium"
                          sx={{ color: "#d43529" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  
                  {/* 
                    <Grid item xs={12} sm={6} md={2} columnSpacing={0}>
                      <TextField 
                          sx={{backgroundColor:'white'}} 
                          size="small" 
                          label="Item Code" 
                          variant="outlined" 
                          type="text"
                          fullWidth 
                          name="item_code" 
                          value={item.item_code}  
                          InputLabelProps={{ shrink: item.item_code?true:false }}
                          InputProps={{ readOnly: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} >
                      <TextField 
                        sx={{backgroundColor:'white'}} 
                        size="small" 
                        label="HSN" 
                        variant="outlined" 
                        type="text"
                        fullWidth 
                        name="hsn_code" value={item.hsn_code} 
                        InputLabelProps={{ shrink: item.hsn_code?true:false }}
                        InputProps={{ readOnly: true }}
                      />
                    </Grid> 
                  */}
                </Grid>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </>
  );
}

export default memo(AddCardSales);
