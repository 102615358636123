function checkUndefinedReturnNull(value) {
  return value !== undefined ? value : null;
}

function checkTextUndefined(value) {
  return value !== undefined ? value : '';
}

function checkTextNull(value) {
  return value !== null ? value : '';
}

function checkTextNullAndUndefined(value) {
  return value !== undefined && value !== null ? value : '';
}

const convertDateFormat = (dateString) => {
  const date = new Date(dateString);

  const padZero = (num) => num.toString().padStart(2, '0');

  const day = padZero(date.getDate());
  const month = padZero(date.getMonth() + 1); // Months are zero-indexed
  const year = date.getFullYear();
  // const hours = padZero(date.getHours());
  // const minutes = padZero(date.getMinutes());
  // const seconds = padZero(date.getSeconds());

  return `${day}-${month}-${year}`;
};

const transformToDate = (value) => {
  if (value) {
    const date = new Date(value);
    return date;
  } 
  else {
    return ''
  }
}

//when value = "2023-11-30"
const transformToDate2 = (value) => {
  if (value) {
    const date = new Date(value);
    const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  } else {
    return '';
  }
};

//when value = "2023-11-30"
const transformToDate3 = (value) => {
  if (value) {
    const date = new Date(value);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString(undefined, options);
  }
  else {
    return '';
  }
};

const roundToInteger = (value) => {
  return Math.round(value);
};

const makePositive = (value) => {
  return Math.abs(value);
}

const capitalizeSentence = (sentence) => {
  // Check if the sentence is not empty
  if (sentence) {
    // Convert the first character to uppercase and the rest to lowercase
    return sentence.charAt(0).toUpperCase() + sentence.slice(1).toLowerCase();
  } 
  else {
    // If the sentence is empty, return an empty string
    return '';
  }
};

const capitalizeWords = (sentence) => {
  // Check if the sentence is not empty
  if (sentence) {
    // Split the sentence into words
    const words = sentence.split(' ');

    // Capitalize the first letter of each word and convert the rest to lowercase
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words back into a sentence
    return capitalizedWords.join(' ');
  } 
  else {
    // If the sentence is empty, return an empty string
    return '';
  }
};

const getIntegerPart = (num) => {
  return Math.floor(num);
}

const checkError = (value) => {
  if (value === null || value === undefined || value === '') {
    return true;
  }
  else{
    return false;
  }
}

const UpdateItemList = (items) => {
  // Function to update the item list by adding a new `id`
  const updatedItemList = items.map((item, index) => ({
    ...item,          // Spread the existing item properties
    id: index + 1,    // Override or add the id field with an incremented value
  }));

  return updatedItemList; // This component only serves as a functional handler, it doesn't render UI
};

const CheckAndReturn = {
  checkUndefinedReturnNull,
  checkTextUndefined,
  checkTextNull,
  checkTextNullAndUndefined,
  convertDateFormat,
  transformToDate,
  transformToDate2,
  transformToDate3,
  roundToInteger,
  makePositive,
  capitalizeSentence,
  capitalizeWords,
  getIntegerPart,
  checkError,
  UpdateItemList
}

export default CheckAndReturn;