import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import configServ from "../../../services/config";
import ActionButtons from "../../Buttons/ActionButtons ";
import CheckAndReturn from "../../../utility/CheckAndReturn";
import { useEffect } from "react";
import DataGridTable from "../../DataGridTable/DataGridTable";
import axios from "axios";
import { appServiceName } from "../../../services/http";
import { useSelector } from "react-redux";
import "./CustomerContactTable.css"; // <-- Import the CSS file here
import { useMediaQuery } from "@mui/material";

const CustomerContactTable = ({ handleEditData, isChanged, customer_id }) => {
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [invoiceDataList, setInvoiceDataList] = useState([]);
  const [invoiceList, setInvoiceList] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(true);
  const columns = [
    {
      field: "sn",
      headerName: "S.NO.",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "contact_name",
      headerName: "Contact Name",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "contact_person_name",
      headerName: "Contact Person Name",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerClassName: "header-style",
    },
    {
      field: "action",
      headerName: "Action",
      width: 130,
      headerClassName: "header-style",
      renderCell: (params) => (
        <ActionButtons
          handlePrint={handlePrint}
          handleEdit={handleEdit}
          id={params.id}
          noPrint
        />
      ),
    },
  ];

  useEffect(() => {
    fetchRecentInvoiceList();
  }, [isChanged]);

  const fetchRecentInvoiceList = async () => {
    try {
      const res = await configServ.getCustomerContact({
        customer_id: customer_id,
      });
      const result = res.map((item, index) => ({
        sn: index + 1,
        id: item.id,
        contact_name: item.contact_name,
        contact_person_name: item.contact_person_name,
        email: item.email,
        mobile: item.mobile,
        address: item.address,
        status: item.status ? "Active" : "Inactive",
      }));
      setInvoiceDataList(res || []);
      setInvoiceList(result || []);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setInvoiceDataList([]);
      setInvoiceList([]);
      setLoading(false);
    }
  };

  const printInvoice = async (invoice_no) => {
    const data = {
      invoice_no: invoice_no,
      user_id: user_id,
      company_id: company_id,
    };
    try {
      const fileName = `invoice${user_id}${invoice_no}`;
      const url = "/api/print_invoice/";
      axios
        .post(`${appServiceName}${url}`, data, {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/pdf",
          },
        })
        .then((res) => {
          const blobFile = new Blob([res.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blobFile);
          window.open(url);
          // link.href = url;
          // const link = document.createElement('a');
          // link.download = fileName;
          // link.click();
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePrint = (params) => {
    const data = invoiceDataList.filter((x) => x.id === params);
    printInvoice(data[0].invoice_number);
  };

  const handleEdit = (params) => {
    scrollToTop();
    const data = invoiceDataList.filter((x) => x.id === params);
    handleEditData(data);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }
  if (isMobile) {
    return (
      <div className="table-container">
        {invoiceList.map((row) => (
          <div key={row.id} className="table-row">
            <div className="table-cell header-cell">
              Contact Name: {row.contact_name}
            </div>
            <div className="table-cell">
              <strong>Person Name:</strong> {row.contact_person_name}
            </div>
            <div className="table-cell"> <strong>Email:</strong> {row.email}</div>
            <div className="table-cell"> <strong>Mobile:</strong> {row.mobile}</div>
            <div className="table-cell"> <strong>Address:</strong> {row.address}</div>
            <div className="table-cell"> <strong>Status:</strong> {row.status}</div>
            <div className="table-cell">
              <ActionButtons
                handlePrint={handlePrint}
                handleEdit={handleEdit}
                id={row.id} // pass the id to the buttons
                noPrint
              />
            </div>
          </div>
        ))}
      </div>
    );
  }

  // Default table view for larger screens
  return <DataGridTable rows={invoiceList} columns={columns} width="100%" />;
};

export default CustomerContactTable;
