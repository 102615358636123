import React, { memo, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4CB5F5',//theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

//FUNCTION
const SupplierLedgerTable = ({ supplierLedger, selectedSupplier }) => {
  //#region code
  const navigate = useNavigate();
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState(0);

  useEffect(() => {
    let totalPurchaseAmount = 0;
    let totalPaidAmount = 0;
    let totalReceivedAmount = 0;

    supplierLedger.forEach(row => {
      totalPurchaseAmount += Number(row.type === 'purchase' ? row.amount ?? 0 : 0);
      totalPaidAmount += Number(row.type === 'payment' ? row.amount ?? 0 : 0);
      totalReceivedAmount += Number(row.type === 'receipt' ? row.amount ?? 0 : 0);
    });

    setPurchaseAmount(CheckAndReturn.roundToInteger(totalPurchaseAmount));
    setPaidAmount(CheckAndReturn.roundToInteger(totalPaidAmount));
    setReceivedAmount(CheckAndReturn.roundToInteger(totalReceivedAmount));

    return () => {
      setPurchaseAmount(0);
      setPaidAmount(0);
      setReceivedAmount(0);
    }
  }, [supplierLedger]);

  const LinkTableCell = styled(StyledTableCell)({
    cursor: 'pointer',
    textDecoration: 'none',
    '&:hover': {
      color: 'darkblue',
      textDecoration: 'underline',
    },
  });

  const handlePurchaseClick = (params) => {
    try {
      navigate('/transaction/purchase-bill', { state: params });
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleVoucherClick = (params) => {
    try {
      navigate('/transaction/create-vouchers', { state: { voucher_no: params, entity: 'supplier' } });
    }
    catch (e) {
      console.log(e);
    }
  }
  //#endregion

  return (
    <>
      <Box flex={1} textAlign="center">
        <Typography variant="h5" color="#1e61b4" fontWeight={700}>
          {selectedSupplier ? selectedSupplier.fname : <CircularProgress />}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Box sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">S.No</StyledTableCell>
                <StyledTableCell align="left">Date</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {supplierLedger.length > 0 ? (
                supplierLedger.map((row, index) => (
                  <StyledTableRow key={row.item_id}>
                    <StyledTableCell align="left">{index + 1}</StyledTableCell>
                    <StyledTableCell align="left">{CheckAndReturn.convertDateFormat(row.type === 'purchase' ? row.billing_date ?? '' : row.transaction_date ?? '')}</StyledTableCell>
                    {
                      row.type === 'purchase' ?
                        <LinkTableCell
                          align="left"
                          onClick={() => handlePurchaseClick(row.purchase_bill_no)}
                        >
                          {`Purchase from(${row.purchase_bill_no ?? ''})`}
                        </LinkTableCell>
                        :
                        <LinkTableCell
                          align="left"
                          onClick={() => handleVoucherClick(row.voucher_no)}
                        >
                          {row.type === 'receipt' ? `Received from(${row.voucher_no ?? ''})` : `Payment(${row.voucher_no ?? ''})`}
                        </LinkTableCell>
                    }
                    <StyledTableCell align="left">₹{row.amount}</StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: 'center', color: 'red', fontSize: '16px' }}>
                    <div>
                      <p><b>No Item Found.</b></p>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>

      <Grid container spacing={1} mt={2}>
        <Grid container item xs={12} md={3} justifyContent={'flex-start'}>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
              Purchase Amount:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
              ₹{purchaseAmount}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={'flex-start'}>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
              Paid Amount:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
              ₹{paidAmount}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={'flex-start'}>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
              Received Amount:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
              ₹{receivedAmount}
            </Typography>
          </Stack>
        </Grid>
        <Grid container item xs={12} md={3} justifyContent={'flex-start'}>
          <Stack direction={'row'}>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600 }}>
              Dues Amount:
            </Typography>
            <Typography variant='body1' sx={{ fontSize: '16px', fontWeight: 600, color: 'green' }}>
              ₹{purchaseAmount - paidAmount + receivedAmount}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(SupplierLedgerTable);
