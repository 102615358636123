import React, { useEffect, useState } from "react";
import DashboardCard from "../../Components/Card/DashboardCard";
import { useSelector } from "react-redux";
import { CircularProgress, Grid } from "@mui/material";
import configServ from "../../services/config";
// icons
import PeopleIcon from "@mui/icons-material/People";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import BalanceIcon from "@mui/icons-material/Balance";
import { useNavigate } from "react-router-dom";
import CheckAndReturn from "../../utility/CheckAndReturn";
import BarGraph from "./BarGraph";
import VoiceCommand from "../../Components/VoiceRecognization/VoiceCommand ";


//FUNCTION
function Dashboard() {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const navigate = useNavigate();

  const [count, setCount] = useState({});

  const fetchDashboardCount = async () => {
    try {
      const userId = user_id;
      if (userId === null) {
        navigate("/");
      }
      const dataToSend = {
        user_id: user_id,
        company_id: company_id,
      };
      const result = await configServ.getDashboardCount(dataToSend);
      setCount(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchDashboardCount();
  }, []);

  const cardData = [
    {
      title: "CUSTOMERS",
      number: count.subscription ?? <CircularProgress />,
      icon: <PeopleIcon sx={{ color: "#fb6340" }} />,
      link: "/account-master-entry/customers",
    },
    {
      title: "INVOICES",
      number: count.invoice ?? <CircularProgress />,
      icon: <DescriptionIcon sx={{ color: "#fb6340" }} />,
      link: "/invoice",
    },
    {
      title: "PAID",
      number: count.paid ?? <CircularProgress />,
      icon: <AttachMoneyIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "UNPAID",
      number: count.unpaid ?? <CircularProgress />,
      icon: <MoneyOffIcon sx={{ color: "#fb6340" }} />,
    },
    {
      title: "OUTSTANDING",
      number:
        count.outstanding || count.outstanding === 0 ? (
          `₹${CheckAndReturn.makePositive(
            CheckAndReturn.roundToInteger(count.outstanding ?? 0)
          )}`
        ) : (
          <CircularProgress />
        ),
      icon: <BalanceIcon sx={{ color: "#fb6340" }} />,
      link: "/report/outstanding-report",
    },
  ];
  //#endregion

  return (
    <>
      <Grid
        container
        alignItems={"center"}
        justifyContent={"flex-start"}
        spacing={2}
        p={2}
        my={1}
        sx={{
          backgroundColor: "#6270e4",
          width: "100%",
        }}
      >
        {cardData.map((item, index) => (
          <DashboardCard index={index} data={item} />
        ))}
      </Grid>
      {/* <BarGraph /> */}
      {/* <VoiceCommand /> */}
    </>
  );
}

export default Dashboard;
