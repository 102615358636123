import React, { memo, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Tooltip,
  Grid,
  FormControl,
  InputLabel,
  NativeSelect,
  TextField,
  InputAdornment,
  Typography,
  CircularProgress,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import configServ from "../../../services/config";
import { useSelector } from "react-redux";
import useDeviceDetect from "../../../Components/Detect/useDeviceDetect ";

//FUNCTION
const BrandTable = ({ isChanged, handleEdit, pagination = true }) => {
  //#region code
  const { company_id, is_super } = useSelector((state) => state.GlobalVariables);
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [loading, setLoading] = useState(true);
  const [brandList, setBrandList] = useState([]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("brand_name");
  const [search, setSearch] = useState("");
  const { isMobile } = useDeviceDetect();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchBrand = async () => {
    try {
      const dataToSend = {
        company_id: company_id,
        is_super: is_super,
        search_by: searchBy,
        search: search,
        per_page: rowsPerPage,
        page: page,
      };
      const res = await configServ.getBrand(dataToSend);
      if (res.status === 200) {
        setBrandList(res.data);
        setCount(res.total);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrand();
  }, [isChanged, page, rowsPerPage, search]);

  const headers = ["BRAND NAME", "DESCRIPTION", "STATUS", "PRIMARY", "ACTION"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

  // const paginatedData = pagination
  //   ? filterBrandList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
  //   : filterBrandList;

  const headerCellStyle = {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearch = (event) => {
    try {
      const data = event.target.value;
      if (data === "") {
        setSearch(null);
      } else {
        setSearch(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <>
        {/* search starts here */}
        <Grid
          container
          alignItems="flex-end"
          justifyContent="flex-start"
          sx={{ pt: 1 }}
          spacing={1}
          direction={"row"}
        >
          <Grid item xs={12} md={6} ml={0} justifyContent={"flex-start"}>
            <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
              Brand List: {count}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  onChange={handleSearchBy}
                  inputProps={{
                    name: "searchBy",
                    id: "uncontrolled-native",
                  }}
                >
                  <option value={"brand_name"}>Brand Name</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            mr={{ xs: 0, md: 0 }}
            justifyContent={"flex-end"}
          >
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: "grey" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        {/* search ends here */}

        {isMobile ? (
          <>
            <Grid container spacing={2}>
              {loading ? (
                <Grid item xs={12} textAlign="center">
                  <CircularProgress />
                </Grid>
              ) : brandList.length > 0 ? (
                brandList.map((row, rowIndex) => (
                  <Grid item xs={12} key={rowIndex}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Stack spacing={1}>
                        <Box sx={{ fontWeight: "bold" }}>
                          {page * rowsPerPage + rowIndex + 1}. {row.brand_name}
                        </Box>
                        <Box>
                          <strong>Description:</strong> {row.description}
                        </Box>
                        <Box>
                          Status:{" "}
                          {row.status === 1 ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </Box>
                        <Box>
                          Primary:{" "}
                          {row.isPrimary === 1 ? (
                            <CheckCircleIcon color="success" />
                          ) : (
                            <CancelIcon color="error" />
                          )}
                        </Box>
                        <Box>
                          <Stack direction="row">
                            <EditIcon
                              sx={{
                                borderRadius: 10,
                                width: "fit-content",
                                transition: "100ms",
                                cursor: "pointer",
                                padding: 0,
                                m: 0.5,
                                ":hover": { backgroundColor: "#99999955" },
                              }}
                              color="primary"
                              onClick={() => {
                                handleEdit(row);
                                scrollToTop();
                              }}
                            />
                          </Stack>
                        </Box>
                      </Stack>
                    </Paper>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign="center">
                  <Box sx={{ color: "red", fontWeight: 600 }}>
                    No data found.
                  </Box>
                </Grid>
              )}
            </Grid>
            {pagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
          </>
        ) : (
          <>
            <TableContainer component={Paper} sx={{ py: 1 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={headerCellStyle}>S.No.</TableCell>
                    {headers.map((header, index) => (
                      <TableCell key={index} sx={headerCellStyle}>
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {brandList.map((row, rowIndex) => (
                    <TableRow
                      key={rowIndex}
                      sx={{
                        ...rowStyle,
                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell sx={cellStyle}>
                        {page * rowsPerPage + rowIndex + 1}
                      </TableCell>
                      <TableCell sx={cellStyle}>{row.brand_name}</TableCell>
                      <TableCell sx={cellStyle}>{row.description}</TableCell>
                      <TableCell sx={cellStyle}>
                        {row.status === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        {row.isPrimary === 1 ? (
                          <CheckCircleIcon color="success" />
                        ) : (
                          <CancelIcon color="error" />
                        )}
                      </TableCell>
                      <TableCell sx={cellStyle}>
                        <Box
                          sx={{
                            borderRadius: 10,
                            width: "fit-content",
                            transition: "100ms",
                            cursor: "pointer",
                            padding: 0.5,
                            ":hover": { backgroundColor: "#99999955" },
                          }}
                          onClick={() => {
                            handleEdit(row);
                            scrollToTop();
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <EditIcon color="primary" />
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 1 * emptyRows }}>
                      <TableCell colSpan={headers.length + 2} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {pagination && (
                <TablePagination
                  rowsPerPageOptions={rowsPerPageOptions}
                  component="div"
                  count={count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </>
        )}
      </>
    );
  }
};

export default memo(BrandTable);
