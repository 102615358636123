import React, { memo, useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Box,
  Typography,
  Grid,
  InputLabel,
  NativeSelect,
  FormControl,
  CircularProgress,
  Button,
  Stack,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PrintIcon from "@mui/icons-material/Print";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { Visibility } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import configServ from "../../services/config";
import SearchBox from "../Search/SearchBox";
import CheckAndReturn from "../../utility/CheckAndReturn";
import SupplierPurchaseBillPopup from "./SupplierLedgerPopup";
import useDebounce from "../hooks/useDebounce";
import { useLocation } from "react-router-dom";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";

//FUNCTION
const SupplierTable = ({ isChanged, handleEdit }) => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const dispatch = useDispatch();
  const rowsPerPageOptions = [20, 50, 100, 200];
  const [page, setPage] = useState(0);
  const [supplierList, setSupplierList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1]);
  const [count, setCount] = useState(0);
  const [searchBy, setSearchBy] = useState("fname");
  const [search, setSearch] = useState(null);
  const debounceSearch = useDebounce(search, 1000);
  const [loading, setLoading] = useState(true);
  const [openSupplierLedger, setOpenSupplierLedger] = useState(false);
  const [supplierLedger, setSupplierLedger] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);

  const location = useLocation();
  const [redirectCustomerName, setRedirectCustomerName] = useState(
    location.state
  );

  useEffect(() => {
    if (redirectCustomerName) {
      //setSearchBy('fname');
      setSearch(redirectCustomerName);
      fetchSuppplierList(redirectCustomerName);
    } else {
      setSearch("");
    }
  }, [redirectCustomerName]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const fetchSuppplierList = async (redirectCustomerName = null) => {
    try {
      setLoading(true);
      const dataToSend = {
        company_id: company_id,
        search_by: searchBy,
        search: redirectCustomerName ? redirectCustomerName : debounceSearch,
        per_page: rowsPerPage,
        page: page,
      };
      const res = await configServ.getSuppliers(dataToSend);
      if (res.status === 200) {
        setSupplierList(res.data);
        setCount(res.total);
      } else {
        setSupplierList([]);
        setCount(0);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!redirectCustomerName) {
      fetchSuppplierList();
    }
  }, [isChanged, page, rowsPerPage]);

  useEffect(() => {
    if (!redirectCustomerName) {
      if (page === 0) {
        fetchSuppplierList();
      } else {
        setPage(0);
      }
    }
  }, [debounceSearch]);

  const headers = ["SUPPLIER", "MOBILE", "AMOUNT", "STATUS", "ACTION"];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, page * rowsPerPage);

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "#4CB5F5",
    color: "white",
  };

  const cellStyle = {
    py: 0,
    my: 0,
  };

  const evenRowStyle = {
    background: "#f5f5f5",
  };

  const oddRowStyle = {
    background: "white",
  };

  const rowStyle = {
    "&:hover": {
      background: "#f1f9fe",
    },
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSupplierLedger = async (params) => {
    try {
      const dataToSend = {
        supplier_id: params.id,
        company_id: company_id,
      };
      const res = await configServ.getSupplierLedger(dataToSend);
      if (res.status === 200) {
        setOpenSupplierLedger(true);
        setSupplierLedger(res.data);
        setSelectedSupplier(params);
      } else {
        setOpenSupplierLedger(false);
        setSupplierLedger([]);
        setSelectedSupplier(null);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleSupplierLedgerClose = () => {
    setOpenSupplierLedger(false);
    setSupplierLedger([]);
    setSelectedSupplier(null);
  };

  const supplierRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => supplierRef.current,
    documentTitle: "Suppliers",
  });

  const handleDownload = () => {
    try {
      dispatch(triggerLoader());
      const input = supplierRef.current;

      html2canvas(input, { scale: 1 }) // Adjust the scale to reduce resolution (e.g., 1 for lower resolution)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/jpeg", 0.75); // Use 'image/jpeg' and reduce quality (0.75 is 75% quality)
          const pdf = new jsPDF();
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight); // Use 'JPEG' instead of 'PNG'
          setTimeout(() => {
            pdf.save("Suppliers.pdf");
            dispatch(triggerLoader());
          }, 5000);
        })
        .catch((error) => {
          console.error("Error generating PDF: ", error);
          dispatch(triggerLoader()); // Stop loader if there's an error
        });
    } catch (e) {
      console.log(e);
      dispatch(triggerLoader()); // Stop loader if there's an error in try block
    }
  };

  //#endregion

  return (
    <>
      <SupplierPurchaseBillPopup
        openSupplierLedger={openSupplierLedger}
        handleSupplierLedgerClose={handleSupplierLedgerClose}
        supplierLedger={supplierLedger}
        selectedSupplier={selectedSupplier}
      />

      {/* search starts here */}
      <Grid
        container
        alignItems="flex-end"
        justifyContent="flex-start"
        sx={{ pt: 1 }}
        spacing={1}
        direction={"row"}
      >
        <Grid container item xs={12} md={3} justifyContent={"flex-start"}>
          <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
            Supplier List: {count}
          </Typography>
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={3}
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <Stack direction={"row"} spacing={2}>
            <Button
              variant="contained"
              onClick={handleDownload}
              startIcon={<DownloadIcon sx={{ ml: 2 }} />}
              title="Download"
              sx={{
                backgroundColor: "#4bcf6b",
                color: "white",
                "&:hover": {
                  backgroundColor: "#00b92f",
                  color: "white",
                },
              }}
            />
            <Button
              variant="contained"
              onClick={handlePrint}
              startIcon={<PrintIcon sx={{ ml: 2 }} />}
              title="Print"
              sx={{
                backgroundColor: "#349af1",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0868F8",
                  color: "white",
                },
              }}
            />
          </Stack>
        </Grid>
        {redirectCustomerName ? (
          <>
            <Grid container item xs={12} md={6} justifyContent={"flex-end"}>
              <Button
                variant="contained"
                title="Referesh"
                color="success"
                startIcon={<RefreshIcon sx={{ ml: 1, color: "white" }} />}
                onClick={() => {
                  setRedirectCustomerName(null);
                }}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <Box sx={{ minWidth: 120, marginLeft: "0px" }}>
                <FormControl fullWidth>
                  <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Search By
                  </InputLabel>
                  <NativeSelect
                    onChange={handleSearchBy}
                    inputProps={{
                      name: "searchBy",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={"fname"}>Supplier Name</option>
                    <option value={"address"}>Address</option>
                    <option value={"phone"}>Phone</option>
                  </NativeSelect>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={12} md={3} justifyContent={"flex-end"}>
              <SearchBox search={search} setSearch={setSearch} />
            </Grid>
          </>
        )}
      </Grid>
      {/* search ends here */}

      {/* desktop views */}

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh", // Set full height
          mt: 3,
          display: { xs: "none", sm: "none", lg: "block" },
        }}
      >
        <Table stickyHeader ref={supplierRef}>
          <TableHead>
            <TableRow>
              <TableCell sx={headerCellStyle}>S.No.</TableCell>
              {headers.map((header, index) => (
                <TableCell key={index} style={headerCellStyle}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <>
              <TableRow>
                <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                  <Box>
                    <CircularProgress />
                  </Box>
                </TableCell>
              </TableRow>
            </>
          ) : (
            <TableBody>
              {supplierList.length > 0 ? (
                supplierList.map((row, rowIndex) => (
                  <TableRow
                    key={rowIndex}
                    sx={{
                      ...rowStyle,
                      ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                    }}
                  >
                    <TableCell sx={cellStyle}>
                      {page * rowsPerPage + rowIndex + 1}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {row.fname}
                      <span style={{ color: "grey" }}>
                        {row.address ? `~${row.address}` : ""}
                      </span>
                    </TableCell>
                    <TableCell sx={cellStyle}>{row.phone}</TableCell>
                    <TableCell sx={cellStyle}>
                      ₹{CheckAndReturn.roundToInteger(row.due_amount ?? 0)}
                      {(row.due_amount ?? 0) > 0 ? (
                        <span style={{ marginLeft: 4, color: "green" }}>
                          Cr
                        </span>
                      ) : (
                        <span style={{ marginLeft: 4, color: "red" }}>Dr</span>
                      )}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      {row.status === 1 ? (
                        <CheckCircleIcon color="success" />
                      ) : (
                        <CancelIcon color="error" />
                      )}
                    </TableCell>
                    <TableCell sx={cellStyle}>
                      <EditIcon
                        color="primary"
                        sx={{
                          borderRadius: 10,
                          width: "fit-content",
                          transition: "100ms",
                          cursor: "pointer",
                          padding: 0,
                          ":hover": { backgroundColor: "#99999955" },
                        }}
                        onClick={() => {
                          handleEdit(row);
                          scrollToTop();
                        }}
                      />
                      <Visibility
                        color="primary"
                        sx={{
                          borderRadius: 10,
                          width: "fit-content",
                          transition: "100ms",
                          cursor: "pointer",
                          padding: 0,
                          ":hover": { backgroundColor: "#99999955" },
                        }}
                        onClick={() => {
                          handleSupplierLedger(row);
                          scrollToTop();
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={12} sx={{ textAlign: "center" }}>
                    <Box sx={{ color: "red" }}>No data found.</Box>
                  </TableCell>
                </TableRow>
              )}
              {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={headers.length + 2} />
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>

        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* mobile view */}
      <Grid
        container
        spacing={2}
        mt={3}
        sx={{ display: { xs: "flex", lg: "none" } }}
      >
        {loading ? (
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          </Grid>
        ) : supplierList.length > 0 ? (
          supplierList.map((row, rowIndex) => (
            <Grid item xs={12} key={row.id}>
              <Paper elevation={2} sx={{ p: 2 }}>
                <Typography variant="h6">
                  S.No: {page * rowsPerPage + rowIndex + 1}
                </Typography>
                <Typography>
                  <strong>Name:</strong> {row.fname}
                </Typography>
                <Typography>
                  <strong>Address:</strong>{" "}
                  {row.address ? `~${row.address}` : "N/A"}
                </Typography>
                <Typography>
                  <strong>Phone:</strong> {row.phone}
                </Typography>
                <Typography>
                  <strong>Due Amount:</strong> ₹
                  {CheckAndReturn.roundToInteger(row.due_amount ?? 0)}
                </Typography>
                {(row.due_amount ?? 0) > 0 ? (
                  <Typography sx={{ color: "green" }}>Cr</Typography>
                ) : (
                  <Typography sx={{ color: "red" }}>Dr</Typography>
                )}
                <Typography>
                  <strong>Status:</strong>{" "}
                  {row.status === 1 ? "Active" : "Inactive"}
                </Typography>

                {/* Actions */}
                <Box sx={{ display: "flex", mt: 2 }}>
                  <EditIcon
                    color="primary"
                    sx={{ cursor: "pointer", marginRight: 2 }}
                    onClick={() => {
                      handleEdit(row);
                      scrollToTop();
                    }}
                  />
                  <Visibility
                    color="primary"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      handleSupplierLedger(row);
                      scrollToTop();
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Box sx={{ color: "red", textAlign: "center" }}>No data found.</Box>
          </Grid>
        )}
      </Grid>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default memo(SupplierTable);
